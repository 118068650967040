import React from "react"
import Image from "next/image"
import { Field } from "formik"

import { Col, Form, InputGroup } from "react-bootstrap"

function PhoneField(props) {
    return (
        <>
            {/* User Phone Number Field */}
            <Col md={12}>
                <Form.Group className="form-input-group">
                    <Form.Label
                        htmlFor="phone"
                        className="d-flex justify-content-between m-0 px-1"
                    >
                        <span className="text-semi-bold text-sm mb-2 ">
                            Phone
                        </span>
                        <span className="text-normal text-sm mb-2">
                            Optional
                        </span>
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                        <InputGroup.Text
                            className="p-0"
                            style={{
                                width: "2.5rem",
                            }}
                        >
                            <Image
                                src="https://webbjenkinsstatic.imgix.net/icons/phone-black.svg"
                                alt="Phone icon"
                                height={16}
                                width={40}
                            />
                        </InputGroup.Text>
                        <Field
                            id="phone"
                            name="phone"
                            className="form-control"
                            aria-label="phone"
                            type="tel"
                        />
                    </InputGroup>
                    {props.formik.errors.phone && props.formik.touched.phone ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.phone}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* User Phone Number Field End */}
        </>
    )
}

export default PhoneField
