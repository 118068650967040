import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

function Recaptcha({ size, captchaRef, formik }) {
    const handleRecaptcha = (value) => {
        fetch(`${process.env.API_BASE_URL}user-forms/recaptcha/`, {
            method: "POST",
            body: JSON.stringify({
                captcha_value: value,
            }),
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then((data) => {
                setCaptchaResult(data.captcha.success)
            })
    }
    return (
        <>
            <ReCAPTCHA
                ref={captchaRef}
                size={size}
                sitekey={process.env.CAPTCHA}
                onChange={
                    (handleRecaptcha,
                    () => {
                        formik.setFieldValue("captcha", true)
                    })
                }
                onExpired={() => {
                    formik.setFieldValue("captcha", false)
                }}
            />
            {formik.errors.captcha && formik.touched.captcha ? (
                <p className="text-danger text-sm">{formik.errors.captcha}</p>
            ) : null}
        </>
    )
}

export default Recaptcha
