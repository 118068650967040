import React, { useState, useEffect, useRef } from "react"

import { Button, Row, Col } from "react-bootstrap"

function SubmitButton(props) {
    return (
        <>
            {/* Submit Button */}
            <Row>
                <Col>
                    <Col
                        as={Button}
                        xs={12}
                        lg={!props.search ? 6 : ""}
                        variant="warning"
                        size={!props.search ? "lg" : ""}
                        type="submit"
                        className={
                            props.search ? "px-4 mt-4 py-2" : "px-4 mt-3"
                        }
                        style={
                            props.search && {
                                marginBottom: "1.3rem",
                            }
                        }
                        // Disable button if form submiting or waiting for
                        // backend response
                        disabled={
                            props.formik.isSubmitting ||
                            props.status === "loading"
                        }
                    >
                        {props.formik.isSubmitting ||
                        props.status === "loading" ? (
                            <div
                                className="spinner-border spinner-settings my-auto"
                                role="status"
                                style={{
                                    height: "1rem",
                                    width: "1rem",
                                }}
                            >
                                <span className="visually-hidden my-auto">
                                    Sending...
                                </span>
                            </div>
                        ) : (
                            <span className="text-bold text-white my-auto">
                                {props.submit_message}
                            </span>
                        )}
                    </Col>
                </Col>
            </Row>
            {/* Submit Button End */}
        </>
    )
}

export default SubmitButton
