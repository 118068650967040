import React from "react"
import Image from "next/image"
import { Field } from "formik"

import { Col, Form, InputGroup } from "react-bootstrap"

function NameField(props) {
    return (
        <>
            {/* User Name Field */}
            <Col md={12}>
                <Form.Group className="form-input-group">
                    <Form.Label htmlFor="fullName">
                        <span className="text-semi-bold text-sm">
                            Full name
                        </span>
                    </Form.Label>
                    <InputGroup className="shadow-sm">
                        <InputGroup.Text
                            className="p-0"
                            style={{
                                width: "2.5rem",
                            }}
                        >
                            <Image
                                src="https://webbjenkinsstatic.imgix.net/icons/avatar.svg"
                                alt="Avatar of a person"
                                height={16}
                                width={40}
                            />
                        </InputGroup.Text>
                        <Field
                            id="fullName"
                            name="fullName"
                            className="form-control"
                            aria-label="fullName"
                            type="text"
                        />
                    </InputGroup>
                    {props.formik.errors.fullName &&
                    props.formik.touched.fullName ? (
                        <p className="text-danger text-sm">
                            {props.formik.errors.fullName}
                        </p>
                    ) : null}
                </Form.Group>
            </Col>
            {/* User Name Field End */}
        </>
    )
}

export default NameField
